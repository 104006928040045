@keyframes prize-card-zoom-in {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  80% {
    transform: scale(1.25);
    opacity: 1;
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes prize-card-expand {
  0% {
    max-width: 44px;
  }
  20% {
    max-width: var(--cardWidth);
  }
  80% {
    max-width: var(--cardWidth);
  }
  100% {
    max-width: 44px;
  }
}

@keyframes prize-card-expand-fade {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
