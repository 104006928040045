@keyframes collected-card-zoom-in {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  80% {
    transform: scale(1.25);
    opacity: 1;
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes collected-card-expand {
  0% {
    max-width: 44px;
  }
  20% {
    max-width: var(--cardWidth);
  }
  80% {
    max-width: var(--cardWidth);
  }
  100% {
    max-width: 44px;
  }
}

@keyframes collected-card-expand-fade {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes collected-card-arrow-expand {
  0% {
    width: 0;
    opacity: 0;
  }
  5% {
    opacity: 1;
  }
  100% {
    width: 100%;
    opacity: 1;
  }
}

@keyframes collected-card-collector-zoom-in {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  5% {
    opacity: 1;
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
