@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Merriweather:wght@300;400;700;900&display=swap');

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

body * {
  font-family: 'Poppins', sans-serif !important;
}

.switch-wrapper {
  position: relative;
}

.switch-wrapper > div {
  position: absolute;
}

@-moz-keyframes spin {
  from {
    -moz-transform: rotate(0deg);
  }
  to {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.gsap-marker-end,
.gsap-marker-start,
.gsap-marker-scroller-end,
.gsap-marker-scroller-start {
  z-index: 9999999 !important;
}
.my-masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  width: auto;
  margin: 20px;
}

@media screen and (max-width: 760px) {
  .my-masonry-grid {
    margin: 0px;
  }
  .my-masonry-grid_column > div {
    /* change div to reference your elements you put in <Masonry> */
    margin: 0px !important;
    margin-top: 40px !important;
  }

  .profile-colector-my-masonry-grid_column > div {
    /* change div to reference your elements you put in <Masonry> */
    margin: 0px !important;
    margin-top: 40px !important;
  }
}
.my-masonry-grid_column {
  background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column > div {
  /* change div to reference your elements you put in <Masonry> */
  /* background: gray; */
  margin: 20px 10px;
}

.profile-colector-my-masonry-grid_column {
  background-clip: padding-box;
}

/* Style your items */
.profile-colector-my-masonry-grid_column > div {
  /* change div to reference your elements you put in <Masonry> */
  background: white;
  margin: 20px 10px;
}

.cbZDOu {
  width: auto !important;
}

.MuiPaper-root.MuiDrawer-paper {
  height: 100vh;
}
button.MuiButtonBase-root.MuiIconButton-root.MuiAutocomplete-popupIndicator {
  display: none;
}
input.MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputAdornedEnd.MuiOutlinedInput-inputAdornedEnd {
  padding: 14px;
}
.MuiInputBase-root.MuiOutlinedInput-root.MuiAutocomplete-inputRoot.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedEnd.MuiOutlinedInput-adornedEnd {
  padding: 0px;
}
.MuiDataGrid-root .MuiDataGrid-cellWithRenderer {
  align-items: flex-start !important;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.MuiAlert-standardSuccess {
  background: #666666f2 !important;
  color: white !important;
}

.MuiAlert-standardInfo {
  background: #b6efc9 !important;
  max-width: 300px !important;
  padding: 16px !important;
  color: #000000 !important;
}

.MuiAlert-icon {
  opacity: initial !important;
  padding: 0 !important;
}
.MuiSelect-root.MuiSelect-select.MuiSelect-selectMenu.Mui-disabled {
  border: 1px solid #bdbdbd;
}
.carousel__slider-tray-wrapper {
  height: auto !important;
  padding-bottom: 0px !important;
}

.MuiFormHelperText-root,
.MuiFormLabel-root {
  font-family: 'Poppins', sans-serif !important;
}

@media only screen and (max-width: 560px) {
  .MuiButton-label > img:not(.fav),
  .MuiIconButton-label > img:not(.fav) {
    transform: scale(1.15);
  }
}

.vertical-bounce {
  -webkit-animation: bounce-top 1.75s infinite alternate both;
  animation: bounce-top 1.75s infinite alternate both;
}

@-webkit-keyframes bounce-top {
  0% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(10px);
    transform: translateY(10px);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    opacity: 1;
  }
}
@keyframes bounce-top {
  0% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(10px);
    transform: translateY(10px);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    opacity: 1;
  }
}
