@keyframes slide-in-top {
  0% {
    top: -20%;
  }

  100% {
    top: 0%;
  }
}

.slide-in-top {
  animation: slide-in-top 0.5s both;
}

@keyframes slide-out-top {
  0% {
    top: 0%;
  }

  100% {
    top: -20%;
  }
}

.slide-out-top {
  animation: slide-out-top 0.5s both;
}

@keyframes slide-in-bottom {
  0% {
    bottom: -20%;
  }

  100% {
    bottom: 0;
  }
}

.slide-in-bottom {
  animation: slide-in-bottom 0.5s both;
}

@keyframes slide-out-bottom {
  0% {
    bottom: 0;
  }

  100% {
    bottom: -20%;
  }
}

.slide-out-bottom {
  animation: slide-out-bottom 0.5s both;
}

@keyframes slide-out-right {
  0% {
    left: 0;
  }
  100% {
    left: 100%;
  }
}
.slide-out-right {
  animation: slide-out-right 0.5s both;
}

@-webkit-keyframes scale-in-hor-right {
  0% {
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
    opacity: 1;
  }

  100% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
    opacity: 1;
  }
}

@keyframes scale-in-hor-right {
  0% {
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
    opacity: 1;
  }

  100% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
    opacity: 1;
  }
}

.scale-in-hor-right {
  -webkit-animation: scale-in-hor-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: scale-in-hor-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes scale-in-ver-top {
  0% {
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
    opacity: 1;
  }

  100% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
    opacity: 1;
  }
}

@keyframes scale-in-ver-top {
  0% {
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
    opacity: 1;
  }

  100% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
    opacity: 1;
  }
}

.scale-in-ver-top {
  -webkit-animation: scale-in-ver-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: scale-in-ver-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes scale-out-ver-top {
  0% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
    opacity: 1;
  }

  100% {
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
    opacity: 1;
  }
}

@keyframes scale-out-ver-top {
  0% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
    opacity: 1;
    height: 'auto';
  }

  100% {
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
    opacity: 1;
    height: 0;
  }
}

.scale-out-ver-top {
  -webkit-animation: scale-out-ver-top 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
  animation: scale-out-ver-top 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
}

@keyframes arrow-bounce {
  0% {
    margin-left: 5px;
  }

  25% {
    margin-left: 0px;
  }

  50% {
    margin-left: -5px;
  }

  75% {
    margin-left: 0px;
  }

  100% {
    margin-left: 5px;
  }
}

.fade-in {
  -webkit-animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

.fade-in-fast {
  -webkit-animation: fade-in 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.fade-out {
  -webkit-animation: fade-out 1s ease-out both;
  animation: fade-out 1s ease-out both;
}

.fade-out-fast {
  -webkit-animation: fade-out 0.4s ease-out both;
  animation: fade-out 0.4s ease-out both;
}

@-webkit-keyframes fade-out {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.fade {
  -webkit-animation: fade 1.5s ease-in-out both;
  animation: fade 1.5s ease-in-out both;
}

@-webkit-keyframes fade {
  0% {
    opacity: 1;
  }

  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fade {
  0% {
    opacity: 1;
  }

  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.fadeInLeft {
  animation: fadeInLeft 1s ease-in-out both;
}

@-webkit-keyframes dialog-slide-in {
  0% {
    -webkit-transform: translateY(100vh);
    transform: translateY(100vh);
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes dialog-slide-in {
  0% {
    -webkit-transform: translateY(100vh);
    transform: translateY(100vh);
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

.dialog-slide-in {
  -webkit-animation: dialog-slide-in 1s cubic-bezier(0.165, 0.84, 0.44, 1) both;
  animation: dialog-slide-in 1s cubic-bezier(0.165, 0.84, 0.44, 1) both;
}

@-webkit-keyframes dialog-slide-out {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  100% {
    -webkit-transform: translateY(100vh);
    transform: translateY(100vh);
  }
}

@keyframes dialog-slide-out {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  100% {
    -webkit-transform: translateY(100vh);
    transform: translateY(100vh);
  }
}

.dialog-slide-out {
  -webkit-animation: dialog-slide-out 1s cubic-bezier(0.165, 0.84, 0.44, 1) both;
  animation: dialog-slide-out 1s cubic-bezier(0.165, 0.84, 0.44, 1) both;
}

@keyframes scale-up-tr {
  0% {
    max-height: 0px;
    overflow: hidden;
    visibility: visible;
  }

  100% {
    max-height: 90vh;
    overflow: hidden;
    visibility: visible;
  }
}

.scale-up-tr {
  animation: scale-up-tr 0.5s both;
}

@keyframes scale-down-tr {
  0% {
    max-height: 90vh;
    overflow: hidden;
    visibility: visible;
  }

  100% {
    max-height: 0px;
    overflow: hidden;
    visibility: hidden;
  }
}

.scale-down-tr {
  animation: scale-down-tr 0.5s both;
}

/* SECONDARY IMAGES ANIMATION */

@-webkit-keyframes slide-top-secondary-images {
  0% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
    z-index: -100;
  }

  100% {
    -webkit-transform: translateY(var(--translateEnd));
    transform: translateY(var(--translateEnd));
    z-index: 100;
  }
}

@keyframes slide-top-secondary-images {
  0% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
    z-index: -100;
  }

  100% {
    -webkit-transform: translateY(var(--translateEnd));
    transform: translateY(var(--translateEnd));
    z-index: 100;
  }
}

.slide-top-secondary-images {
  -webkit-animation: slide-top-secondary-images 0.35s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-top-secondary-images 0.35s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes slide-bottom-secondary-images {
  0% {
    -webkit-transform: translateY(var(--translateStart));
    transform: translateY(var(--translateStart));
  }

  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
    opacity: 0;
  }
}

@keyframes slide-bottom-secondary-images {
  0% {
    -webkit-transform: translateY(var(--translateStart));
    transform: translateY(var(--translateStart));
  }

  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
    opacity: 0;
  }
}

.slide-bottom-secondary-images {
  -webkit-animation: slide-bottom-secondary-images 0.35s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-bottom-secondary-images 0.35s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes slide-top-lines {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }

  100% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
    opacity: 0;
  }
}

@keyframes slide-top-lines {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }

  100% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
    opacity: 0;
  }
}

.slide-top-lines {
  -webkit-animation: slide-top-lines 0.25s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-top-lines 0.25s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes slide-bottom-lines {
  0% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateY(1px);
    transform: translateY(1px);
    opacity: 1;
  }
}

@keyframes slide-bottom-lines {
  0% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateY(1px);
    transform: translateY(1px);
    opacity: 1;
  }
}

.slide-bottom-lines {
  -webkit-animation: slide-bottom-lines 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-bottom-lines 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

/* END OF SECONDARY IMAGES ANIMATION */

@keyframes fade-out {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.fade-out {
  animation: fade-out 0.5s both;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.fade-in {
  animation: fade-in 0.5s both;
}

/* SHINE ANIMATION RECOGNIZE BUTTON */
.shine-1 {
  overflow: hidden;
}

.shine-1:before {
  content: '';
  position: absolute;
  top: 0;
  left: -100px;
  width: 20px;
  height: 100%;
  background: rgba(255, 255, 255, 0.3);
  opacity: 0.7;
  transform: skewX(-45deg);
  animation-name: shineEffect;
  animation-duration: 650ms;
  animation-timing-function: ease-in-out;
  animation-delay: 350ms;
  animation-iteration-count: infinite;
  animation-direction: normal;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0.13) 0%,
    rgba(255, 255, 255, 0.13) 77%,
    rgba(255, 255, 255, 0.5) 92%,
    rgba(255, 255, 255, 0) 100%
  );
}
@keyframes shineEffect {
  0% {
    left: 0;
    top: 0;
  }
  100% {
    left: 100%;
    top: 0;
  }
}
/* END OF SHINE ANIMATION RECOGNIZE BUTTON */
/* GREEN PULSE ANIMATION */
@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 #2dc161b3;
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 3px 10px rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}
/* END OF PULSE ANIMATION */
