/*
  ======================================================================
  Desktop Feed to Showcase / Showcase to Feed
  ======================================================================
*/
/* Enter Feed */
.feed-desktop-enter {
  opacity: 0;
  /* transform: translate(0, -50vh) scale(0.97);
  filter: blur(20px); */
}

.feed-desktop-enter-active {
  opacity: 1;
  transition-duration: 0s;
  /* transform: translate(0, 0) scale(1);
  filter: blur(0px);
  transition-property: all;
  transition-duration: var(--showcase-transition-time);
  transition-timing-function: cubic-bezier(1, 0, 0, 0); */
}

/* Exit Feed */
.feed-desktop-exit {
  opacity: 1;
  top: var(--artist-feed-last-scroll);
  /* transform: translate(0, 0) scale(1); */
  /* filter: blur(0px); */
}

.feed-desktop-exit-active {
  opacity: 0;
  /* transform: translate(0, -50vh) scale(0.97); */
  /* filter: blur(20px); */
  transition-property: all;
  transition-duration: var(--showcase-transition-time);
  /* transition-timing-function: cubic-bezier(0.45, 1, 0, 1); */
  transition-timing-function: linear;
}

/* Enter Showcase */
.showcase-desktop-enter {
  opacity: 0;
  transform: translate(0, 10vh);
  /* filter: blur(20px); */
}
.showcase-desktop-enter-active {
  opacity: 1;
  transform: translate(0, 0);
  /* filter: blur(0px); */
  transition-property: all;
  transition-duration: var(--showcase-transition-time);
  /* transition-timing-function: cubic-bezier(1, 0, 0, 0); */
  transition-timing-function: cubic-bezier(0, 0.1, 0.2, 1);
}

.showcase-desktop-enter .hidden-during-transition {
  opacity: 0 !important;
}

.showcase-desktop-enter-done .hidden-during-transition {
  opacity: 1;
}

.showcase-desktop-enter .showcase-element-slide-from-bottom {
  opacity: 0;
}

.showcase-desktop-enter-done .showcase-element-slide-from-bottom {
  opacity: 1;
  transition: opacity 1s ease-in-out;
}

.showcase-desktop-enter .showcase-element-slide-from-left {
  left: -50px;
}

.showcase-desktop-enter-done .showcase-element-slide-from-left {
  left: 10px;
  transition: opacity 0.5s ease-in, left 1s ease-in-out;
}

.showcase-desktop-enter .showcase-element-slide-from-right {
  right: -50px;
}

.showcase-desktop-enter-done .showcase-element-slide-from-right {
  right: 10px;
  transition: opacity 0.5s ease-in, right 1s ease-in-out;
}

/* Exit Showcase */
.showcase-desktop-exit {
  opacity: 1;
}
.showcase-desktop-exit-active {
  opacity: 0;
  transition-duration: 0s;
}

/* 
  ======================================================================
  Mobile Feed to Showcase / Showcase to Feed 
  ======================================================================
*/

/* Enter Feed */
.feed-mobile-enter {
  opacity: 1;
}

/* Exit Feed */
.feed-mobile-exit {
  opacity: 1;
}

/* Enter Showcase */
.showcase-mobile-enter {
  opacity: 1;
  transform: translateY(7vh);
}

.showcase-mobile-enter-active {
  opacity: 1;
  transform: translate(0, 0) scale(1);
  transition-property: all;
  transition-duration: var(--showcase-transition-time);
  transition-timing-function: ease-in-out;
}

.showcase-mobile-enter .hidden-during-transition {
  opacity: 0;
}

.showcase-mobile-enter-done .hidden-during-transition {
  opacity: 1;
  transition: all var(--mobile-showcase-element-transition-time) ease-in-out;
}

.showcase-mobile-enter .showcase-element-slide-from-bottom {
  bottom: calc(-80px - env(safe-area-inset-bottom));
}

.showcase-mobile-enter-done .showcase-element-slide-from-bottom {
  bottom: calc(0px + env(safe-area-inset-bottom));
  transition: all var(--mobile-showcase-element-transition-time) ease-in-out;
}

.artworkshowcase-enter-active .shown-during-transition {
  opacity: 1;
}

.artworkshowcase-enter-done .shown-during-transition {
  opacity: 0;
  pointer-events: none;
}

/* Exit Showcase */
.showcase-mobile-exit {
  opacity: 1;
}
.showcase-mobile-exit-active {
  opacity: 0;
}

/*
  ======================================================================
  Desktop Showcase to Showcase - Fade Transition
  ======================================================================
*/
/* Enter */
/* showcase-body */
.desktop-showcase-fade-enter {
  opacity: 0;
}

.desktop-showcase-fade-enter-active {
  opacity: 1;
  transition-property: opacity;
  transition-duration: var(--showcase-transition-time);
  transition-timing-function: linear;
}

/* hidden-during-transition */
.desktop-showcase-fade-enter .hidden-during-transition {
  opacity: 0;
  transition: all var(--mobile-showcase-element-transition-time) ease-in-out;
}

.desktop-showcase-fade-enter-done .hidden-during-transition {
  opacity: 1;
  transition: all var(--mobile-showcase-element-transition-time) ease-in-out;
}

/* 
showcase desktop top left and bottom slide in animations 
.showcase-details-loaded gets set on the transition-group from src\stacks\ShowcaseNewV2\views\DesktopShowcase.tsx
*/
#feed-to-showcase-transition-group .general-showcase-wrapper .showcase-element-slide-from-left {
  left: -50px;
}
#feed-to-showcase-transition-group.showcase-details-loaded .general-showcase-wrapper .showcase-element-slide-from-left {
  left: 10px;
  transition: opacity 0.5s ease-in, left 1s ease-in-out;
}

#feed-to-showcase-transition-group .general-showcase-wrapper .showcase-element-slide-from-right {
  right: -50px;
}
#feed-to-showcase-transition-group.showcase-details-loaded
  .general-showcase-wrapper
  .showcase-element-slide-from-right {
  right: 10px;
  transition: opacity 0.5s ease-in, right 1s ease-in-out;
}

#feed-to-showcase-transition-group .general-showcase-wrapper .showcase-element-slide-from-top {
  top: -50px;
}
#feed-to-showcase-transition-group.showcase-details-loaded .general-showcase-wrapper .showcase-element-slide-from-top {
  top: 10px;
  transition: opacity 0.5s ease-in, top 1s ease-in-out;
}

/* Exit */
/* showcase-body */
.desktop-showcase-fade-exit {
  opacity: 1;
}

.desktop-showcase-fade-exit-active {
  opacity: 0;
  transition-property: opacity;
  transition-duration: var(--showcase-transition-time);
  transition-timing-function: linear;
}

/* hide-during-exit */
.desktop-showcase-fade-exit .hide-during-exit {
  opacity: 0;
}

.desktop-showcase-fade-exit-active .hide-during-exit {
  opacity: 0;
}

/* hidden-during-transition */
.desktop-showcase-fade-exit .hidden-during-transition {
  opacity: 1;
  transition: all var(--mobile-showcase-element-transition-time) ease-in-out;
}

.desktop-showcase-fade-exit-active .hidden-during-transition {
  opacity: 0;
  transition: all var(--mobile-showcase-element-transition-time) ease-in-out;
}

/*
  ======================================================================
  Mobile Showcase to Showcase - Slide Transition
  ======================================================================
*/
/* Enter */
/* showcase-body */
.mobile-showcase-slide-enter .showcase-body {
  transform: translate(var(--showcase-slide-enter-horizontal), var(--showcase-slide-enter-vertical));
}

.mobile-showcase-slide-enter-active .showcase-body {
  transform: translate(0vw, 0vh);
  transition-property: transform;
  transition-duration: var(--showcase-transition-time);
  transition-timing-function: linear;
}

/* hide-during-enter */
.mobile-showcase-slide-enter .hide-during-enter {
  opacity: 0;
}

.mobile-showcase-slide-enter-active .hide-during-enter {
  opacity: 0;
}

.mobile-showcase-slide-enter-done .hide-during-enter {
  opacity: 1;
}

/* hidden-during-transition */
.mobile-showcase-slide-enter .hidden-during-transition {
  opacity: 0;
  transition: all var(--mobile-showcase-element-transition-time) ease-in-out;
}

.mobile-showcase-slide-enter-done .hidden-during-transition {
  opacity: 1;
  transition: all var(--mobile-showcase-element-transition-time) ease-in-out;
}

/* showcase-element-slide-from-bottom */
.mobile-showcase-slide-enter .showcase-element-slide-from-bottom {
  bottom: calc(-80px - env(safe-area-inset-bottom));
}

.mobile-showcase-slide-enter-done .showcase-element-slide-from-bottom {
  bottom: calc(0px + env(safe-area-inset-bottom));
  transition: all var(--mobile-showcase-element-transition-time) ease-in-out;
}

/* Exit */
/* showcase-body */
.mobile-showcase-slide-exit .showcase-body {
  transform: translate(0vw, 0vh);
  overflow: 'hidden';
}

.mobile-showcase-slide-exit-active .showcase-body {
  transform: translate(var(--showcase-slide-exit-horizontal), var(--showcase-slide-exit-vertical));
  transition-property: transform;
  transition-duration: var(--showcase-transition-time);
  transition-timing-function: linear;
}

/* hidden-during-transition */
.mobile-showcase-slide-exit .hidden-during-transition {
  opacity: 1;
  transition: all var(--mobile-showcase-element-transition-time) ease-in-out;
}

.mobile-showcase-slide-exit-active .hidden-during-transition {
  opacity: 0;
  transition: all var(--mobile-showcase-element-transition-time) ease-in-out;
}

/* showcase-element-slide-from-bottom */

.mobile-showcase-slide-exit .showcase-element-slide-from-bottom {
  bottom: calc(0px + env(safe-area-inset-bottom));
  transition: all var(--mobile-showcase-element-transition-time) ease-in-out;
}

.mobile-showcase-slide-exit-active .showcase-element-slide-from-bottom {
  bottom: calc(-80px - env(safe-area-inset-bottom));
  transition: all var(--mobile-showcase-element-transition-time) ease-in-out;
}

.mobile-showcase-slide-exit .hide-background-on-slide {
  background: unset !important;
}
