@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Merriweather:wght@300;400;700;900&display=swap);
@keyframes collected-card-zoom-in {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  80% {
    transform: scale(1.25);
    opacity: 1;
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes collected-card-expand {
  0% {
    max-width: 44px;
  }
  20% {
    max-width: var(--cardWidth);
  }
  80% {
    max-width: var(--cardWidth);
  }
  100% {
    max-width: 44px;
  }
}

@keyframes collected-card-expand-fade {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes collected-card-arrow-expand {
  0% {
    width: 0;
    opacity: 0;
  }
  5% {
    opacity: 1;
  }
  100% {
    width: 100%;
    opacity: 1;
  }
}

@keyframes collected-card-collector-zoom-in {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  5% {
    opacity: 1;
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes prize-card-zoom-in {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  80% {
    transform: scale(1.25);
    opacity: 1;
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes prize-card-expand {
  0% {
    max-width: 44px;
  }
  20% {
    max-width: var(--cardWidth);
  }
  80% {
    max-width: var(--cardWidth);
  }
  100% {
    max-width: 44px;
  }
}

@keyframes prize-card-expand-fade {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

body * {
  font-family: 'Poppins', sans-serif !important;
}

.switch-wrapper {
  position: relative;
}

.switch-wrapper > div {
  position: absolute;
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.gsap-marker-end,
.gsap-marker-start,
.gsap-marker-scroller-end,
.gsap-marker-scroller-start {
  z-index: 9999999 !important;
}
.my-masonry-grid { /* Not needed if autoprefixing */ /* Not needed if autoprefixing */
  display: flex;
  width: auto;
  margin: 20px;
}

@media screen and (max-width: 760px) {
  .my-masonry-grid {
    margin: 0px;
  }
  .my-masonry-grid_column > div {
    /* change div to reference your elements you put in <Masonry> */
    margin: 0px !important;
    margin-top: 40px !important;
  }

  .profile-colector-my-masonry-grid_column > div {
    /* change div to reference your elements you put in <Masonry> */
    margin: 0px !important;
    margin-top: 40px !important;
  }
}
.my-masonry-grid_column {
  background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column > div {
  /* change div to reference your elements you put in <Masonry> */
  /* background: gray; */
  margin: 20px 10px;
}

.profile-colector-my-masonry-grid_column {
  background-clip: padding-box;
}

/* Style your items */
.profile-colector-my-masonry-grid_column > div {
  /* change div to reference your elements you put in <Masonry> */
  background: white;
  margin: 20px 10px;
}

.cbZDOu {
  width: auto !important;
}

.MuiPaper-root.MuiDrawer-paper {
  height: 100vh;
}
button.MuiButtonBase-root.MuiIconButton-root.MuiAutocomplete-popupIndicator {
  display: none;
}
input.MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputAdornedEnd.MuiOutlinedInput-inputAdornedEnd {
  padding: 14px;
}
.MuiInputBase-root.MuiOutlinedInput-root.MuiAutocomplete-inputRoot.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedEnd.MuiOutlinedInput-adornedEnd {
  padding: 0px;
}
.MuiDataGrid-root .MuiDataGrid-cellWithRenderer {
  align-items: flex-start !important;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.MuiAlert-standardSuccess {
  background: #666666f2 !important;
  color: white !important;
}

.MuiAlert-standardInfo {
  background: #b6efc9 !important;
  max-width: 300px !important;
  padding: 16px !important;
  color: #000000 !important;
}

.MuiAlert-icon {
  opacity: initial !important;
  padding: 0 !important;
}
.MuiSelect-root.MuiSelect-select.MuiSelect-selectMenu.Mui-disabled {
  border: 1px solid #bdbdbd;
}
.carousel__slider-tray-wrapper {
  height: auto !important;
  padding-bottom: 0px !important;
}

.MuiFormHelperText-root,
.MuiFormLabel-root {
  font-family: 'Poppins', sans-serif !important;
}

@media only screen and (max-width: 560px) {
  .MuiButton-label > img:not(.fav),
  .MuiIconButton-label > img:not(.fav) {
    transform: scale(1.15);
  }
}

.vertical-bounce {
  animation: bounce-top 1.75s infinite alternate both;
}
@keyframes bounce-top {
  0% {
    transform: translateY(-10px);
    animation-timing-function: ease-in;
    opacity: 1;
  }
  100% {
    transform: translateY(10px);
    animation-timing-function: ease-out;
    opacity: 1;
  }
}

@keyframes slide-in-top {
  0% {
    top: -20%;
  }

  100% {
    top: 0%;
  }
}

.slide-in-top {
  animation: slide-in-top 0.5s both;
}

@keyframes slide-out-top {
  0% {
    top: 0%;
  }

  100% {
    top: -20%;
  }
}

.slide-out-top {
  animation: slide-out-top 0.5s both;
}

@keyframes slide-in-bottom {
  0% {
    bottom: -20%;
  }

  100% {
    bottom: 0;
  }
}

.slide-in-bottom {
  animation: slide-in-bottom 0.5s both;
}

@keyframes slide-out-bottom {
  0% {
    bottom: 0;
  }

  100% {
    bottom: -20%;
  }
}

.slide-out-bottom {
  animation: slide-out-bottom 0.5s both;
}

@keyframes slide-out-right {
  0% {
    left: 0;
  }
  100% {
    left: 100%;
  }
}
.slide-out-right {
  animation: slide-out-right 0.5s both;
}

@keyframes scale-in-hor-right {
  0% {
    transform: scaleX(0);
    transform-origin: 100% 100%;
    opacity: 1;
  }

  100% {
    transform: scaleX(1);
    transform-origin: 100% 100%;
    opacity: 1;
  }
}

.scale-in-hor-right {
  animation: scale-in-hor-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@keyframes scale-in-ver-top {
  0% {
    transform: scaleY(0);
    transform-origin: 100% 0%;
    opacity: 1;
  }

  100% {
    transform: scaleY(1);
    transform-origin: 100% 0%;
    opacity: 1;
  }
}

.scale-in-ver-top {
  animation: scale-in-ver-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@keyframes scale-out-ver-top {
  0% {
    transform: scaleY(1);
    transform-origin: 100% 0%;
    opacity: 1;
    height: 'auto';
  }

  100% {
    transform: scaleY(0);
    transform-origin: 100% 0%;
    opacity: 1;
    height: 0;
  }
}

.scale-out-ver-top {
  animation: scale-out-ver-top 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
}

@keyframes arrow-bounce {
  0% {
    margin-left: 5px;
  }

  25% {
    margin-left: 0px;
  }

  50% {
    margin-left: -5px;
  }

  75% {
    margin-left: 0px;
  }

  100% {
    margin-left: 5px;
  }
}

.fade-in {
  animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

.fade-in-fast {
  animation: fade-in 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.fade-out {
  animation: fade-out 1s ease-out both;
}

.fade-out-fast {
  animation: fade-out 0.4s ease-out both;
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.fade {
  animation: fade 1.5s ease-in-out both;
}

@keyframes fade {
  0% {
    opacity: 1;
  }

  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.fadeInLeft {
  animation: fadeInLeft 1s ease-in-out both;
}

@keyframes dialog-slide-in {
  0% {
    transform: translateY(100vh);
  }

  100% {
    transform: translateY(0);
  }
}

.dialog-slide-in {
  animation: dialog-slide-in 1s cubic-bezier(0.165, 0.84, 0.44, 1) both;
}

@keyframes dialog-slide-out {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(100vh);
  }
}

.dialog-slide-out {
  animation: dialog-slide-out 1s cubic-bezier(0.165, 0.84, 0.44, 1) both;
}

@keyframes scale-up-tr {
  0% {
    max-height: 0px;
    overflow: hidden;
    visibility: visible;
  }

  100% {
    max-height: 90vh;
    overflow: hidden;
    visibility: visible;
  }
}

.scale-up-tr {
  animation: scale-up-tr 0.5s both;
}

@keyframes scale-down-tr {
  0% {
    max-height: 90vh;
    overflow: hidden;
    visibility: visible;
  }

  100% {
    max-height: 0px;
    overflow: hidden;
    visibility: hidden;
  }
}

.scale-down-tr {
  animation: scale-down-tr 0.5s both;
}

/* SECONDARY IMAGES ANIMATION */

@keyframes slide-top-secondary-images {
  0% {
    transform: translateY(0px);
    z-index: -100;
  }

  100% {
    transform: translateY(var(--translateEnd));
    z-index: 100;
  }
}

.slide-top-secondary-images {
  animation: slide-top-secondary-images 0.35s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@keyframes slide-bottom-secondary-images {
  0% {
    transform: translateY(var(--translateStart));
  }

  100% {
    transform: translateY(0px);
    opacity: 0;
  }
}

.slide-bottom-secondary-images {
  animation: slide-bottom-secondary-images 0.35s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@keyframes slide-top-lines {
  0% {
    transform: translateY(0);
    opacity: 1;
  }

  100% {
    transform: translateY(-10px);
    opacity: 0;
  }
}

.slide-top-lines {
  animation: slide-top-lines 0.25s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@keyframes slide-bottom-lines {
  0% {
    transform: translateY(-10px);
    opacity: 0;
  }

  100% {
    transform: translateY(1px);
    opacity: 1;
  }
}

.slide-bottom-lines {
  animation: slide-bottom-lines 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

/* END OF SECONDARY IMAGES ANIMATION */

@keyframes fade-out {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.fade-out {
  animation: fade-out 0.5s both;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.fade-in {
  animation: fade-in 0.5s both;
}

/* SHINE ANIMATION RECOGNIZE BUTTON */
.shine-1 {
  overflow: hidden;
}

.shine-1:before {
  content: '';
  position: absolute;
  top: 0;
  left: -100px;
  width: 20px;
  height: 100%;
  background: rgba(255, 255, 255, 0.3);
  opacity: 0.7;
  transform: skewX(-45deg);
  animation-name: shineEffect;
  animation-duration: 650ms;
  animation-timing-function: ease-in-out;
  animation-delay: 350ms;
  animation-iteration-count: infinite;
  animation-direction: normal;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0.13) 0%,
    rgba(255, 255, 255, 0.13) 77%,
    rgba(255, 255, 255, 0.5) 92%,
    rgba(255, 255, 255, 0) 100%
  );
}
@keyframes shineEffect {
  0% {
    left: 0;
    top: 0;
  }
  100% {
    left: 100%;
    top: 0;
  }
}
/* END OF SHINE ANIMATION RECOGNIZE BUTTON */
/* GREEN PULSE ANIMATION */
@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 #2dc161b3;
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 3px 10px rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}
/* END OF PULSE ANIMATION */

/*
  ======================================================================
  Desktop Feed to Showcase / Showcase to Feed
  ======================================================================
*/
/* Enter Feed */
.feed-desktop-enter {
  opacity: 0;
  /* transform: translate(0, -50vh) scale(0.97);
  filter: blur(20px); */
}

.feed-desktop-enter-active {
  opacity: 1;
  transition-duration: 0s;
  /* transform: translate(0, 0) scale(1);
  filter: blur(0px);
  transition-property: all;
  transition-duration: var(--showcase-transition-time);
  transition-timing-function: cubic-bezier(1, 0, 0, 0); */
}

/* Exit Feed */
.feed-desktop-exit {
  opacity: 1;
  top: var(--artist-feed-last-scroll);
  /* transform: translate(0, 0) scale(1); */
  /* filter: blur(0px); */
}

.feed-desktop-exit-active {
  opacity: 0;
  /* transform: translate(0, -50vh) scale(0.97); */
  /* filter: blur(20px); */
  transition-property: all;
  transition-duration: var(--showcase-transition-time);
  /* transition-timing-function: cubic-bezier(0.45, 1, 0, 1); */
  transition-timing-function: linear;
}

/* Enter Showcase */
.showcase-desktop-enter {
  opacity: 0;
  transform: translate(0, 10vh);
  /* filter: blur(20px); */
}
.showcase-desktop-enter-active {
  opacity: 1;
  transform: translate(0, 0);
  /* filter: blur(0px); */
  transition-property: all;
  transition-duration: var(--showcase-transition-time);
  /* transition-timing-function: cubic-bezier(1, 0, 0, 0); */
  transition-timing-function: cubic-bezier(0, 0.1, 0.2, 1);
}

.showcase-desktop-enter .hidden-during-transition {
  opacity: 0 !important;
}

.showcase-desktop-enter-done .hidden-during-transition {
  opacity: 1;
}

.showcase-desktop-enter .showcase-element-slide-from-bottom {
  opacity: 0;
}

.showcase-desktop-enter-done .showcase-element-slide-from-bottom {
  opacity: 1;
  transition: opacity 1s ease-in-out;
}

.showcase-desktop-enter .showcase-element-slide-from-left {
  left: -50px;
}

.showcase-desktop-enter-done .showcase-element-slide-from-left {
  left: 10px;
  transition: opacity 0.5s ease-in, left 1s ease-in-out;
}

.showcase-desktop-enter .showcase-element-slide-from-right {
  right: -50px;
}

.showcase-desktop-enter-done .showcase-element-slide-from-right {
  right: 10px;
  transition: opacity 0.5s ease-in, right 1s ease-in-out;
}

/* Exit Showcase */
.showcase-desktop-exit {
  opacity: 1;
}
.showcase-desktop-exit-active {
  opacity: 0;
  transition-duration: 0s;
}

/* 
  ======================================================================
  Mobile Feed to Showcase / Showcase to Feed 
  ======================================================================
*/

/* Enter Feed */
.feed-mobile-enter {
  opacity: 1;
}

/* Exit Feed */
.feed-mobile-exit {
  opacity: 1;
}

/* Enter Showcase */
.showcase-mobile-enter {
  opacity: 1;
  transform: translateY(7vh);
}

.showcase-mobile-enter-active {
  opacity: 1;
  transform: translate(0, 0) scale(1);
  transition-property: all;
  transition-duration: var(--showcase-transition-time);
  transition-timing-function: ease-in-out;
}

.showcase-mobile-enter .hidden-during-transition {
  opacity: 0;
}

.showcase-mobile-enter-done .hidden-during-transition {
  opacity: 1;
  transition: all var(--mobile-showcase-element-transition-time) ease-in-out;
}

.showcase-mobile-enter .showcase-element-slide-from-bottom {
  bottom: calc(-80px - env(safe-area-inset-bottom));
}

.showcase-mobile-enter-done .showcase-element-slide-from-bottom {
  bottom: calc(0px + env(safe-area-inset-bottom));
  transition: all var(--mobile-showcase-element-transition-time) ease-in-out;
}

.artworkshowcase-enter-active .shown-during-transition {
  opacity: 1;
}

.artworkshowcase-enter-done .shown-during-transition {
  opacity: 0;
  pointer-events: none;
}

/* Exit Showcase */
.showcase-mobile-exit {
  opacity: 1;
}
.showcase-mobile-exit-active {
  opacity: 0;
}

/*
  ======================================================================
  Desktop Showcase to Showcase - Fade Transition
  ======================================================================
*/
/* Enter */
/* showcase-body */
.desktop-showcase-fade-enter {
  opacity: 0;
}

.desktop-showcase-fade-enter-active {
  opacity: 1;
  transition-property: opacity;
  transition-duration: var(--showcase-transition-time);
  transition-timing-function: linear;
}

/* hidden-during-transition */
.desktop-showcase-fade-enter .hidden-during-transition {
  opacity: 0;
  transition: all var(--mobile-showcase-element-transition-time) ease-in-out;
}

.desktop-showcase-fade-enter-done .hidden-during-transition {
  opacity: 1;
  transition: all var(--mobile-showcase-element-transition-time) ease-in-out;
}

/* 
showcase desktop top left and bottom slide in animations 
.showcase-details-loaded gets set on the transition-group from src\stacks\ShowcaseNewV2\views\DesktopShowcase.tsx
*/
#feed-to-showcase-transition-group .general-showcase-wrapper .showcase-element-slide-from-left {
  left: -50px;
}
#feed-to-showcase-transition-group.showcase-details-loaded .general-showcase-wrapper .showcase-element-slide-from-left {
  left: 10px;
  transition: opacity 0.5s ease-in, left 1s ease-in-out;
}

#feed-to-showcase-transition-group .general-showcase-wrapper .showcase-element-slide-from-right {
  right: -50px;
}
#feed-to-showcase-transition-group.showcase-details-loaded
  .general-showcase-wrapper
  .showcase-element-slide-from-right {
  right: 10px;
  transition: opacity 0.5s ease-in, right 1s ease-in-out;
}

#feed-to-showcase-transition-group .general-showcase-wrapper .showcase-element-slide-from-top {
  top: -50px;
}
#feed-to-showcase-transition-group.showcase-details-loaded .general-showcase-wrapper .showcase-element-slide-from-top {
  top: 10px;
  transition: opacity 0.5s ease-in, top 1s ease-in-out;
}

/* Exit */
/* showcase-body */
.desktop-showcase-fade-exit {
  opacity: 1;
}

.desktop-showcase-fade-exit-active {
  opacity: 0;
  transition-property: opacity;
  transition-duration: var(--showcase-transition-time);
  transition-timing-function: linear;
}

/* hide-during-exit */
.desktop-showcase-fade-exit .hide-during-exit {
  opacity: 0;
}

.desktop-showcase-fade-exit-active .hide-during-exit {
  opacity: 0;
}

/* hidden-during-transition */
.desktop-showcase-fade-exit .hidden-during-transition {
  opacity: 1;
  transition: all var(--mobile-showcase-element-transition-time) ease-in-out;
}

.desktop-showcase-fade-exit-active .hidden-during-transition {
  opacity: 0;
  transition: all var(--mobile-showcase-element-transition-time) ease-in-out;
}

/*
  ======================================================================
  Mobile Showcase to Showcase - Slide Transition
  ======================================================================
*/
/* Enter */
/* showcase-body */
.mobile-showcase-slide-enter .showcase-body {
  transform: translate(var(--showcase-slide-enter-horizontal), var(--showcase-slide-enter-vertical));
}

.mobile-showcase-slide-enter-active .showcase-body {
  transform: translate(0vw, 0vh);
  transition-property: transform;
  transition-duration: var(--showcase-transition-time);
  transition-timing-function: linear;
}

/* hide-during-enter */
.mobile-showcase-slide-enter .hide-during-enter {
  opacity: 0;
}

.mobile-showcase-slide-enter-active .hide-during-enter {
  opacity: 0;
}

.mobile-showcase-slide-enter-done .hide-during-enter {
  opacity: 1;
}

/* hidden-during-transition */
.mobile-showcase-slide-enter .hidden-during-transition {
  opacity: 0;
  transition: all var(--mobile-showcase-element-transition-time) ease-in-out;
}

.mobile-showcase-slide-enter-done .hidden-during-transition {
  opacity: 1;
  transition: all var(--mobile-showcase-element-transition-time) ease-in-out;
}

/* showcase-element-slide-from-bottom */
.mobile-showcase-slide-enter .showcase-element-slide-from-bottom {
  bottom: calc(-80px - env(safe-area-inset-bottom));
}

.mobile-showcase-slide-enter-done .showcase-element-slide-from-bottom {
  bottom: calc(0px + env(safe-area-inset-bottom));
  transition: all var(--mobile-showcase-element-transition-time) ease-in-out;
}

/* Exit */
/* showcase-body */
.mobile-showcase-slide-exit .showcase-body {
  transform: translate(0vw, 0vh);
  overflow: 'hidden';
}

.mobile-showcase-slide-exit-active .showcase-body {
  transform: translate(var(--showcase-slide-exit-horizontal), var(--showcase-slide-exit-vertical));
  transition-property: transform;
  transition-duration: var(--showcase-transition-time);
  transition-timing-function: linear;
}

/* hidden-during-transition */
.mobile-showcase-slide-exit .hidden-during-transition {
  opacity: 1;
  transition: all var(--mobile-showcase-element-transition-time) ease-in-out;
}

.mobile-showcase-slide-exit-active .hidden-during-transition {
  opacity: 0;
  transition: all var(--mobile-showcase-element-transition-time) ease-in-out;
}

/* showcase-element-slide-from-bottom */

.mobile-showcase-slide-exit .showcase-element-slide-from-bottom {
  bottom: calc(0px + env(safe-area-inset-bottom));
  transition: all var(--mobile-showcase-element-transition-time) ease-in-out;
}

.mobile-showcase-slide-exit-active .showcase-element-slide-from-bottom {
  bottom: calc(-80px - env(safe-area-inset-bottom));
  transition: all var(--mobile-showcase-element-transition-time) ease-in-out;
}

.mobile-showcase-slide-exit .hide-background-on-slide {
  background: unset !important;
}

